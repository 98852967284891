<template>
  <section class="block catalog general">
    <CommonTitle :options="options_title" />
    <CommonTable :options="options_table" />
  </section>
</template>

<script>
import Axios from 'axios';
import CommonTable from '../../common/CommonTable';
import CommonTitle from '../../common/CommonTitle';

export default {
  name: 'CatalogTableMain',
  data() {
    return {
      options_title: {
        name: 'Каталог песен',
        id: 'catalog',
        actions: '',
      },
      options_table: {
        id: 'catalog',
        request: 'sounds',
        actions: 'edit-remove',
        ths: [
          { id: 'status_id', name: 'Статус', width: '7%', value: '', sort: false, search: true },
          { id: 'sound_id', name: 'ID', width: '6%', value: '', sort: true, search: true },
          { id: 'name', name: 'Название', width: '8%', value: '', sort: true, search: true },
          { id: 'music_author_id', name: 'Музыка', width: '7%', value: '', sort: true, search: true },
          { id: 'text_author_id', name: 'Текст', width: '7%', value: '', sort: true, search: true },
          { id: 'executor_id', name: 'Исполнитель', width: '9%', value: '', sort: true, search: true },
          { id: 'fragment', name: 'Фрагмент', width: '11%', value: '', sort: true, search: true },
          { id: 'fragment_order', name: 'Порядок', width: '10%', value: '', sort: true, search: true },
          { id: 'genre_id', name: 'Жанр', width: '5%', value: '', sort: false, search: true },
          { id: 'language_id', name: 'Язык', width: '6%', value: '', sort: true, search: true },
          { id: 'film_id', name: 'Фильм', width: '6%', value: '', sort: true, search: true },
          { id: 'difficulty_id', name: 'СЛЖ', width: '6%', value: '', sort: true, search: true, hint: true, fullname: 'Сложность', show: false },
          { id: 'img', name: 'Фото', width: '5%', value: '', sort: false, search: false },
          { id: 'sound', name: 'Песня', width: '5%', value: '', sort: false, search: false },
          { id: 'rights_holder', name: 'ПО', width: '8%', value: '', sort: true, search: true },
          { id: '', name: '', width: '3%', value: '', sort: false, search: false },
        ],
        table: {
          variables: ['status_id', 'sound_id', 'name', 'music_author_id', 'text_author_id', 'executor_id', 'fragment', 'fragment_order', 'genre_id', 'language_id', 'film_id', 'difficulty_id', 'img', 'sound', 'rights_holder'],
          page: 0,
          limit: 100,
          total: 0,
          data: null
        }
      }
    }
  },
  mounted() {
    this.$bus.$on('uploadFiles', (result) => {
      const sound = this.options_table.table.data.find(el => el._id === result._id);
      if (sound) {
        sound.sound = result.sound;
        sound.img = result.img;
      }
    });
    this.$bus.$on('createSound', (result) => {
      this.options_table.table.data.push(result.data);
    });
    this.$bus.$on('createSounds', (result) => {
      result.forEach(sound => {
        this.options_table.table.data.push(sound);
      })
    });
    this.$bus.$on('editSound', (result) => {
      const sound = this.options_table.table.data.find(el => el._id === result.data._id);
      if (sound) {
        sound.status_id = result.data.status_id;
        sound.sound_id = result.data.sound_id;
        sound.name = result.data.name;
        sound.music_author_id = result.data.music_author;
        sound.text_author_id = result.data.text_author;
        sound.executor_id = result.data.executor;
        sound.genre_id = result.data.genre;
        sound.language_id = result.data.language;
        if (sound.film_id) sound.film_id.name = result.data.film;
        if (sound.difficulty_id) sound.difficulty_id.name = result.data.difficulty;
        if (sound.filter_id) sound.filter_id.name = result.data.filter;
        sound.fragment = result.data.fragment;
        sound.fragment_order = result.data.fragment_order;
        sound.rights_holder = result.data.rights_holder;
      }
    });
    this.$bus.$on('removeSound', (result) => {
      this.options_table.table.data = this.options_table.table.data.filter(el => el._id !== result.data);
    });
    Axios
      .get(`/api/sounds`, {
        headers: {
          token: this.$store.getters.token
        },
        params: {
          limit: 100,
          page: 0
        }
      })
      .then(res => {
        this.options_table.table.data = res.data.data;
        this.options_table.table.page = 0;
        this.options_table.table.limit = 100;
        this.options_table.table.total = res.data.total;
      })
      .catch(err => {
        console.error(err.response);
      })
  },
  components: {
    CommonTitle,
    CommonTable
  }
}
</script>

<style>
.catalog {
  display: flex;
  flex-direction: column;
}
</style>