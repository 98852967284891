<template>
  <section class="pages">
    <div class="pages__list">
      <div 
        v-for="page of pages" 
        :key="page.name" 
        :class="{ active: selectedPage === page.component }" 
        @click="selectPage(page.id)"
        v-text="page.name"
      />
    </div>
    <component :is="selectedPage"/>
  </section>
</template>

<script>
import _ from 'lodash';

import CatalogTableMain from './pages/CatalogTableMain';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CatalogMain',

  data: () => ({
    selectedPage: CatalogTableMain,
    pages: [
      { id: 'catalog', name: 'Каталог песен', component: CatalogTableMain },
      { id: 'stats', name: 'Выгрузка статистики', component: null },
    ],
  }),

  methods: {
    selectPage(pageId) {
      if (pageId !== 'stats') return;
      const modal = 'music-stats-get';
      const element = {};
      this.$emit('create-modal', { modal, element });
      this.$store.dispatch('createModal', { modal, element });
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
  flex-grow: 1;
  overflow: auto;
  &__list {
    display: flex;
    padding: 25px 25px 0px 25px;
    > div {
      color: #00325C;
      font-weight: 500;
      font-size: 20px;
      cursor: pointer;
      padding: 5px 12px 15px 12px;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &:hover {
        color: #054b85;
        transition: 0.3s all;
      }
      &.active {
        box-shadow: inset 0 -3px 0 #6461F6;
      }
    }
  }
}
</style>