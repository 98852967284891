<template>
  <CatalogMain />
</template>

<script>
import CatalogMain from '@/components/catalog/CatalogMain';

export default {
  name: 'Catalog',
  components: {
    CatalogMain
  }
}
</script>

<style>

</style>